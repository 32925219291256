import React from "react";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import imgHeart from "../../assets/image/inner-page/png/heart-icon-group.png";
import imgStars from "../../assets/image/inner-page/png/star-group.png";
import imgSAP from "../../assets/image/inner-page/png/sap.png";
import imgA3 from "../../assets/image/inner-page/png/a3innuva.png";

const Counter = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row justify-content-center justify-content-lg-start">
            {/* Left */}
            <div className="col-xl-5 col-lg-6 col-md-9">
              <div className="row">
                {/* Single Counter */}
                <div className="col-xs-6">
                  <div
                    className="mb-11 mb-lg-0"
                    data-aos="fade-left"
                    data-aos-once="true"
                  >
                    <h2 className="font-size-12 mb-6">
                      <LazyLoad>
                        <span className="counter">
                          <CountUp duration={4} end={549} />
                        </span>
                      </LazyLoad>
                    </h2>
                    <div className="mt-5">
                      <img className="mb-7" src={imgHeart} alt="" />
                      <h5 className="font-size-5 font-weight-normal mb-1">
                        Likes
                      </h5>
                      <p className="font-size-5 text-dodger-blue-2 mb-0">
                        de empleados
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Counter */}
                {/* Single Counter */}
                <div className="col-xs-6">
                  <div
                    className="mb-11 mb-lg-0"
                    data-aos="fade-left"
                    data-aos-once="true"
                  >
                    <h2 className="font-size-12 mb-6">
                      <LazyLoad>
                        <CountUp
                          duration={4}
                          start={1.2}
                          end={4.7}
                          decimal="."
                          decimals={1}
                        />
                      </LazyLoad>
                    </h2>
                    <div className="mt-5">
                      <img className="mb-7" src={imgStars} alt="" />
                      <h5 className="font-size-5 font-weight-normal mb-1">
                        Satisfacción
                      </h5>
                      <p className="font-size-5 text-dodger-blue-2 mb-0">
                        de clientes
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Counter */}
              </div>
            </div>
            {/* Left End */}
            {/* Right Start */}
            <div className="col-lg-6 offset-xl-1">
              <div className="row">
                <div className="col-lg-12 col-md-7 col-xs-9">
                  <div
                    className="section-title"
                    data-aos="flip-up"
                    data-aos-once="true"
                  >
                    <h2 className="font-size-9 pr-xl-22 mb-7 mb-lg-11 pr-lg-0">
                      Nuestros clientes han quedado satisfechos con la puesta en
                      marcha.
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h6 className="font-size-6 font-weight-normal text-stone mb-5 mb-lg-9">
                    Nos integramos con las mejores marcas
                  </h6>
                  <div className="row align-items-center">
                    {/* Single Brand */}
                    <div
                      className="single-brand-logo px-5 my-6"
                      data-aos="fade-in"
                      data-aos-duration={400}
                      data-aos-once="true"
                    >
                      <img src={imgSAP} alt="" />
                    </div>
                    <div
                      className="single-brand-logo px-5 my-6"
                      data-aos="fade-in"
                      data-aos-duration={400}
                      data-aos-delay={200}
                      data-aos-once="true"
                    >
                      <img src={imgA3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Right End */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Counter;
